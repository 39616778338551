<template>
  <main class="layout-special">
    <header class="layer-primary">
      <div class="main-img">
        <img loading="lazy" src="https://cdn.maine-et-loire.fr/ecosysteme_ressources/img/random_une.png" alt="" width="1800" height="650" />
      </div>
    </header>

    <section class="section-content" id="section-content">
      <header>
        <ul id="site-nav-breadcrumbs" class="site-nav-breadcrumbs">
          <li>
            <router-link :to="{ name: 'accueil' }">Accueil</router-link>
          </li>
          <li>Plan du site</li>
        </ul>
        <h1>Plan du site</h1>
      </header>

      <div>
        <article>
          <div id="sitemap">
            <ul>
              <li v-for="item in orderedPages" v-if="item.path != '*'">
                <router-link :to="item.path">
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </article>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      items: []
    };
  },
  created() {
    this.$router.options.routes.forEach((route) => {
      this.items.push({
        name: route.meta.title,
        path: route.path
      });
    });
  },
  computed: {
    orderedPages: function () {
      return this.items.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
};
</script>